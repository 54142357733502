<template>
    <div>
        <el-form :model="form_data" :rules="formRules" :ref="form_name" :disabled="formDisabled" size="mini" label-position="right" label-width="100px">
            <el-form-item label="场次名称:" prop="title"><el-input v-model="form_data.title" style="width: 100%"></el-input></el-form-item>
            <el-form-item label="场地:" prop="playground_id">
                <el-select v-model="form_data.playground_id" filterable placeholder="请选择场地"  style="width:calc(100% - 60px)" @change="onSelectPlaygroundChange">
                    <el-option v-for="(p) in params.playground" :key="p.key" :label="p.value" :value="p.key"></el-option>
                </el-select>
                <span style="cursor: pointer;color:#409EFF;font-weight:bold;text-decoration-line: underline;margin-left:16px;" @click="onCopyTitleTap">复制</span>
            </el-form-item>
            <el-form-item label="限定人数:" prop="people_limit_up">
<!--                <el-input-number v-model="form_data.people_limit_up" :step="1" :min="0" :precision="0" :max="select_playground&&select_playground.people_limit_up || 0"></el-input-number>-->
                <el-input-number v-model="form_data.people_limit_up" :step="1" :min="0" :precision="0" ></el-input-number>
                <span v-if="select_playground && select_playground.id" style="padding:0 16px;">上限 <span style="font-weight:bold;color:#409EFF;">{{select_playground.people_limit_up}}</span> 人</span>
                <span style="cursor: pointer;color:#409EFF;font-weight:bold;text-decoration-line: underline" @click="onSetMaxPeopleTap">设置最大</span>
<!--                <span style="cursor: pointer;color:#409EFF;font-weight:bold;">设置最大</span>-->
            </el-form-item>
<!--            <el-form-item label="开始时间:" prop="begin_at"><el-input v-model="form_data.begin_at"></el-input></el-form-item>-->
<!--            <el-form-item label="结束时间:" prop="end_at"><el-input v-model="form_data.end_at"></el-input></el-form-item>-->
<!--            <el-form-item label="场次时间:" prop="time_range">-->
<!--                <el-time-picker is-range type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" v-model="form_data.time_range"></el-time-picker>-->
<!--            </el-form-item>-->
            <el-form-item label="场次:" prop="play_type">
                <el-radio-group v-model="form_data.play_type">
<!--                    <el-radio-button label="none">无</el-radio-button>-->
<!--                    <el-radio-button label="single">散场</el-radio-button>-->
<!--                    <el-radio-button label="team">包场</el-radio-button>-->
<!--                    <el-radio-button label="coach">教练场</el-radio-button>-->
                    <el-radio-button v-for="(p) in params.play_type" :key="p.key"  :label="p.key">{{p.value}}</el-radio-button>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="教练:" v-if="form_data.play_type == 3">
                <el-select v-model="form_data.coach_id" filterable placeholder="请选择教练"  style="width:calc(100% - 60px)" @change="onSelectCoachChange">
                    <el-option v-for="(p) in params.coach" :key="p.key" :label="p.value" :value="p.key"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="进阶课程:" v-if="form_data.play_type == 3">
                <el-select v-model="form_data.advance_course_id" filterable placeholder="请选择进阶课程"  style="width:calc(100% - 60px)">
                    <el-option v-for="(p) in params.advance_course" :key="p.key" :label="p.value" :value="p.key"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="开始时间:">
                <el-date-picker v-model="form_data.begin_at" type="datetime" placeholder="选择日期时间" format="YYYY-MM-DD HH:00:00"></el-date-picker>
            </el-form-item>
            <el-form-item label="价格:" prop="price"><el-input-number v-model="form_data.price" :step="1" :min="0.01" :precision="2"></el-input-number></el-form-item>
<!--            <el-form-item label="散场价格:" prop="price"><el-input-number v-model="form_data.price" :step="1" :min="0.01" :precision="2"></el-input-number></el-form-item>-->
<!--            <el-form-item label="包场价格:" prop="price"><el-input-number v-model="form_data.team_price" :step="1" :min="0.01" :precision="2"></el-input-number></el-form-item>-->


        </el-form>
      <div style="display: flex;align-items: center;justify-content: space-between">
        <div style="text-align:left;">
          <el-button @click="onRemoveTap(rowId)" size="mini" style="color:#F56C6C;">删除</el-button>
        </div>
        <div style="text-align:right;">
            <el-button v-if="action!=='view'" type="primary" @click="submitForm(form_name)" size="mini">提交编辑</el-button>
            <el-button @click="onCloseDialog" size="mini">关闭</el-button>
        </div>
      </div>
    </div>
</template>

<script>
    import {getParams} from "@/api/hado/param";
    import {getDetail, remove, submit} from "@/api/hado/play-schedule";
    import{getDetail as getPlaygroundDetail} from "@/api/hado/playground";
    import{getDetail as getCoachDetail} from "@/api/hado/coach";

    const param_list = ['playground_type','playground','play_type','coach','advance_course']
    export default {
        name: "play-schedule-detail",
        props:{
            action:{
                value:String,
                default:'add'
            },
            rowId:{
                value:String,
            },
            playgroundId:{
                value:String,
                default:''
            },
            beginAt:{
                value:Number,
                default:0
            }
        },
        data(){
            return{
                form_name:'play_schedule_detail',
                form_data:{time_range:[]},
                formRules:{},
                params:{},
                params_map:{},
                params_map_reverse:{},

                select_playground:null,
                select_coach:null
            }
        },
        computed:{
            formDisabled(){
                return this.action === 'view' || !this.action;
            }
        },

        watch:{
            // 'form_data.playground_id'(nv,ov){
            //     console.log('playground_id',nv,ov)
            //     //场地更新的时候 获取场地信息 赋值其他字段
            //     let that = this
            //     getPlaygroundDetail(nv).then(resp=>{
            //         let pd = resp.data
            //         that.select_playground = resp.data
            //         that.form_data.price = pd.price
            //         // that.form_data.title = pd.
            //         that.form_data.title = `${pd.code}||${pd.title}`
            //         that.form_data.people_limit_up = that.form_data.people_limit_up>pd.people_limit_up ? pd.people_limit_up :that.form_data.people_limit_up
            //     })
            // }
        },
        created(){
            this.initPage()
        },

        methods:{
            initParams(){
                return new Promise((resolve,reject)=>{
                    getParams(param_list.join(','),'zh-CN').then(resp=>{
                        this.params = resp.data;

                        let tmp_params_map = {}
                        let tmp_params_map_reverse = {}

                        //这里再这里一下每组参数做成kv方便显示
                        let p_keys = Object.keys(this.params)
                        p_keys.forEach(pl=>{
                            // console.log('pl:',pl)
                            let tmp_map = {}
                            let tmp_map_reverse = {}
                            this.params[pl].forEach(pm=>{
                                // tmp_map[pm.key]=pm.value
                                tmp_map[pm.key]={value:pm.value,color:pm.color}
                                //再调转处理一下 导入Excel参数解析用
                                tmp_map_reverse[pm.value]=pm.key
                            })
                            tmp_params_map[pl] = tmp_map
                            tmp_params_map_reverse[pl] = tmp_map_reverse
                        })

                        this.params_map = tmp_params_map
                        this.params_map_reverse = tmp_params_map_reverse
                        // console.log('params_map:',this.params_map)
                        // console.log('params_map_reverse:',this.params_map_reverse)
                        resolve(resp)
                    }).catch((err)=>{
                        if(err){
                            console.error(err)
                        }
                        reject(err)
                    })
                });
            },
            initPage(){
              // console.log('schedule add:',this.begin_at,this.playground_id)
              console.log('add:',this.rowId)
                this.initParams().then(()=>{
                    if(this.rowId>0){
                        getDetail(this.rowId).then(resp=>{
                            this.form_data = resp.data
                            this.prepareSelectPlayGround()
                            this.prepareSelectCoach()
                        })
                    }else if(this.action === 'add'){
                            console.log('schedule add:',this.beginAt,this.playgroundId)
                            this.form_data.playground_id = this.playgroundId
                            this.form_data.begin_at =  this.beginAt
                            this.prepareSelectPlayGround()
                            this.prepareSelectCoach()
                        // })

                    }
                })
            },
            onCloseDialog(){
                this.$emit("onTellDialogClose")
            },
            submitForm(formName){
                if(this.form_data.time_range && this.form_data.time_range.length == 2){
                    this.form_data.begin_at = new Date(this.form_data.time_range[0]).getTime();
                    this.form_data.end_at =  new Date(this.form_data.time_range[1]).getTime();
                }
                if(this.form_data.play_type!=3){
                    this.form_data.coach_id = ''
                    this.form_data.advance_course_id=''
                }
                this.form_data.begin_at = new Date(this.form_data.begin_at).getTime()
                this.$refs[formName].validate((valid)=>{
                    if(valid){
                        submit(this.form_data).then(()=>{
                            this.$emit('onTellDialogClose')
                        })
                    }
                })
            },
            onSetMaxPeopleTap(){
                if(this.select_playground&&this.select_playground.id){
                    this.form_data.people_limit_up = this.select_playground.people_limit_up;
                }
            },
            onSelectPlaygroundChange(e){
                let that = this
                console.log('onSelectPlaygroundChange:',e)
                getPlaygroundDetail(e).then(resp=>{
                    // let pd = resp.data
                    that.select_playground = resp.data
                    // that.form_data.price = pd.price
                    // that.form_data.title = `${pd.code}||${pd.title}`
                    // that.form_data.people_limit_up = that.form_data.people_limit_up>pd.people_limit_up ? pd.people_limit_up :that.form_data.people_limit_up
                })
            },
            onSelectCoachChange(e){
                let that = this
                getCoachDetail(e).then(resp=>{
                    that.select_coach = resp.data
                })
            },
            prepareSelectPlayGround(){
                if(this.form_data.playground_id && this.form_data.playground_id>0){
                    getPlaygroundDetail(this.form_data.playground_id).then(resp=>{
                        // let pd = resp.data
                        this.select_playground = resp.data
                        // that.form_data.price = pd.price
                        // that.form_data.title = `${pd.code}||${pd.title}`
                        // that.form_data.people_limit_up = that.form_data.people_limit_up>pd.people_limit_up ? pd.people_limit_up :that.form_data.people_limit_up
                    })
                }
            },
            prepareSelectCoach(){
                if(this.form_data.coach_id&&this.form_data.coach_id>0){
                    getCoachDetail(this.form_data.coach_id).then(resp=>{
                        this.select_coach = resp.data
                    })
                }
            },
            onCopyTitleTap(){
                if(this.select_playground&&this.select_playground.id){
                    this.form_data.title =`${this.select_playground.code}||${this.select_playground.title}`
                }
            },
            onRemoveTap(row) {
              this.$messageBox.confirm('确定删除当前数据?', '提示', {
                cancelButtonText: '取消',
                confirmButtonText: '确定'
              }).then(() => {
                remove(row).finally(() => {
                  this.$emit('onTellDialogClose')
                })
              })
            }
        }
    }
</script>

<style scoped>

</style>
